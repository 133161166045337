.account-container {
	margin: 5rem auto;
}

.account-image {
	width: 100%;
	border-radius: 0.5rem;
}

#account-button {
	background-color: var(--roxo) !important;
	border-color: var(--roxo) !important;
}
