.card-product {
	padding: 1rem;
	transition: 0.2s;
	border: solid 1px #ddd;
	height: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.card-product:hover {
	box-shadow: 0px 3px 5px 0px #ccc;
	transition: 0.5s;
	cursor: pointer;
}

.card-product-image-container {
	margin: 1.5rem auto !important;
	display: flex !important;
	align-items: center;
	justify-self: center;
	overflow: hidden;
	height: 200px;
}

.card-product-image-container img {
	width: auto;
	max-width: 100%;
	max-height: 150px;
}

.card-product-button {
	display: flex !important;
	align-items: center !important;
	gap: 1rem !important;
	background-color: var(--vermelho) !important;
	border-color: var(--vermelho) !important;
	padding: 0.7rem 1rem !important;
}
