:root {
	--color-1: #009b7d;
	--color-2: #009b7d;
	--color-3: #bbbbbb;
	--vermelho: #e53238;
	--amarelo: #f2a900;
	--azul: #0e6eb8;
	--roxo: #6c63ff;
	--branco: #f9f9f9;
}

* {
	font-family: "Rubik", sans-serif !important;
	box-sizing: border-box !important;
}

a {
	text-decoration: none;
}

section {
	margin-bottom: 5rem;
}

.card {
	border-radius: 0.2rem !important;
}

.form-control {
	border-radius: 3px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.form-group {
	margin-bottom: 1rem !important;
}

.g-1 {
	gap: 1rem;
}

.navbar-brand a {
	color: #121212;
	font-family: "Pacifico", cursive !important;
	font-size: 1.5rem;
}

.page-title {
	font-size: 2rem;
	margin: 0 0 1.5rem;
}

.page-subtitle {
	font-size: 1.2rem;
	margin: 0 0 1.5rem;
}
