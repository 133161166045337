footer {
	margin-top: 7rem;
	background-color: var(--color-2);
	color: var(--branco);
	padding: 2rem 0 0;
}

footer h5 {
	color: #121212;
	font-weight: bold;
	margin-bottom: 1rem;
}

footer .nav a {
	color: inherit;
	white-space: nowrap;
	margin-right: 1rem;
}

.contact-list {
	list-style: none;
	padding: 0;
}

.contact-list li {
	margin-bottom: 1rem;
}

.copyright {
	text-align: center;
	background-color: var(--color-1);
	padding: 1rem 0;
	margin: 1rem auto 0;
	font-weight: bold;
	color: #f6f6f6;
}

footer .acesso-rapido,
footer .sobre {
	margin-top: 2rem;
}

footer .redes-sociais {
	display: flex;
	justify-content: center;
	gap: 2rem;
	margin-top: 3rem;
}

footer .redes-sociais a {
	margin: 0;
}
