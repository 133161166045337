.card-local {
	border: none !important;
}

.card-local-name {
	text-align: center;
	margin-top: 1rem !important;
}

.card-local-city {
	text-align: center;
	color: #595959;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
}
