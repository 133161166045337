.form-search-products {
	display: flex;
	gap: 1rem;
	background-color: #fff;
	border: solid 1px var(--color-1);
}

.form-search-products input,
.form-search-products button {
	height: 3rem !important;
	border: none;
	height: 50px !important;
	outline: none;
}

.form-search-products input:focus,
.form-search-products input:active,
.form-search-products input:target {
	outline: none !important;
	box-shadow: none !important;
}

.form-search-products button,
.form-search-products button:hover,
.form-search-products button:active,
.form-search-products button:focus,
.form-search-products button:focus-visible {
	background-color: transparent !important;
	border-color: transparent !important;
	outline: none !important;
}

.form-search-products-container {
	background-color: var(--color-1);
    background-image: url("../../assets/supermarket.jpg");
    background-position: bottom;
	padding: 5rem 0;
	position: relative;
    display: flex;
    align-items: center;
    min-height: 450px;
}

.recording {
	animation: blink 1s infinite linear;
}

@keyframes blink {
	from {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}
